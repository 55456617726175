// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".content_rUTLW{display:grid;gap:2rem;grid-template-columns:repeat(2,1fr)}.secondContainer_NUJjd{display:flex;flex-direction:column}.cardsWrapper_mghkH{display:grid;gap:2rem;grid-template-rows:repeat(4,1fr)}.video_LD52-{position:relative}.video_LD52-:hover .icon_SjZb5{transform:scale(1.2)}.iconWrapper_OEyuN{align-items:center;background-color:#fff;border-radius:50%;cursor:pointer;display:flex;height:3.2rem;justify-content:center;left:48%;position:absolute;top:33%;width:3.2rem}.icon_SjZb5{transition:scale .3s;width:.8rem}@media(max-width:767.98px){.VideoBlock_Sqs8f{max-width:90vw;padding:0}.firstContainer_mmMwG{margin-right:0;max-width:100%}.description_\\+cTLf{margin-bottom:2.4rem}.content_rUTLW{display:flex;flex-direction:column;gap:0}.cardsWrapper_mghkH{display:none}.iconWrapper_OEyuN{left:47%;top:29%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-tablet": "5.6rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"content": "content_rUTLW",
	"secondContainer": "secondContainer_NUJjd",
	"cardsWrapper": "cardsWrapper_mghkH",
	"video": "video_LD52-",
	"icon": "icon_SjZb5",
	"iconWrapper": "iconWrapper_OEyuN",
	"VideoBlock": "VideoBlock_Sqs8f",
	"firstContainer": "firstContainer_mmMwG",
	"description": "description_+cTLf"
};
module.exports = ___CSS_LOADER_EXPORT___;
